import React from "react";
import CardImage from "../../images/jobs/card.png";
import ListImage from "../../images/jobs/list.png";
import CardHoverImage from "../../images/jobs/card_hover.png";
import ListHoverImage from "../../images/jobs/list_hover.png";
const ListCardToggle = ({ cardView, listView, onClickHandler }) => {
  return (
    <div className="jobs-list-card-toggle-wrapper">
      {cardView ? (
        <img
          src={CardHoverImage}
          alt="goken careers"
          className="card-view-image "
        />
      ) : (
        <img
          onClick={() => onClickHandler(false)}
          src={CardImage}
          alt="goken careers "
          className="card-view-image not-active"
        />
      )}
      {listView ? (
        <img
          src={ListHoverImage}
          alt="goken careers"
          className="list-view-image"
        />
      ) : (
        <img
          onClick={() => onClickHandler(true)}
          src={ListImage}
          alt="goken careers "
          className="list-view-image not-active"
        />
      )}
    </div>
  );
};

export default ListCardToggle;
