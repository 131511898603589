import JobListView from "./JobCard";
import React from "react";
import JobCardView from "./JobCardView";
const JobList = ({ jobs, cardView, toggle = true }) => {
  console.log(jobs);
  return toggle ? (
    <div className="job-list-wrapper w-90-wrapper">
      {/* <div className="experiment-absolute-div">hellow</div> */}
      {jobs.map((job, index) => (
        <JobListView
          key={`${index}+job.text`}
          name={job.text}
          postId={job.id}
          commitment={job.categories.commitment}
          location={job.categories.location}
        />
      ))}
    </div>
  ) : (
    <div className="job-card-view-wrapper w-90-wrapper">
      {jobs.map((job, index) => (
        <JobCardView
          key={`${index}+job.text`}
          name={job.text}
          postId={job.id}
          description={job.descriptionPlain}
          commitment={job.categories.commitment}
          location={job.categories.location}
          lists={job.lists ? job.lists : []}
        />
      ))}
    </div>
  );
};
export default JobList;
