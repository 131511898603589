// import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
// import { MultiSelect } from "react-multi-select-component";
import ReactSelect, { components } from "react-select";
import { useLanguage } from "../../../languageContext";
import DropDownOption from "./DropDownOption";

const CustomMultiSelect = ({ onChangeHandler, icon, data = [], name = "" }) => {
  const [selected, setSelected] = useState([]);
  const currentLanguage = useLanguage();
  // const overrideStrings = {
  //   allItemsAreSelected: "All items are selected.",
  //   clearSearch: "Clear Search",
  //   clearSelected: "Clear Selected",
  //   noOptions: "No options",
  //   search: "Search",
  //   selectAll: "Select All",
  //   selectAllFiltered: "Select All (Filtered)",
  //   selectSomeItems: `Select ${name}`,
  //   create: "Create",
  // };
  const onChangeCustomHandler = (selectedArr) => {
    onChangeHandler(selectedArr);

    setSelected(selectedArr);
  };
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "red" : "blue",
      padding: 20,
      color: `${
        currentLanguage === "en"
          ? "#6fb544"
          : currentLanguage === "in"
          ? "#25aad1"
          : currentLanguage === "ja"
          ? "#c32728"
          : "#6fb544"
      }`,
      backgroundColor: "white",
    }),
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      borderRadius: "25px",
      border: `solid 1px ${
        currentLanguage === "en"
          ? "#6fb544"
          : currentLanguage === "in"
          ? "#25aad1"
          : currentLanguage === "ja"
          ? "#c32728"
          : "#6fb544"
      }`,
      color: `${
        currentLanguage === "en"
          ? "#6fb544"
          : currentLanguage === "in"
          ? "#25aad1"
          : currentLanguage === "ja"
          ? "#c32728"
          : "#6fb544"
      }`,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };
  return (
    <div className="para-text custom-multiselect-wrapper">
      <ReactSelect
        options={data}
        className={`custom-multiselect-dropdown multiselect-border-${
          currentLanguage ? currentLanguage : "en"
        }`}
        placeholder={`${name}`}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={onChangeCustomHandler}
        allowSelectAll={true}
        value={selected}
        styles={customStyles}
      />
      {/* <pre>{JSON.stringify(...selected)}</pre> */}
      {/* <MultiSelect
        options={data}
        value={selected}
        onChange={onChangeCustomHandler}
        overrideStrings={overrideStrings}
        className={`multiselect-border-${
          currentLanguage ? currentLanguage : "en"
        }`}
        labelledBy={`${name !== undefined ? name : null}`}
      /> */}
      {/* <Multiselect options={data} select /> */}
    </div>
  );
};

export default CustomMultiSelect;
