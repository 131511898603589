import React from "react";
import MultiSelect from "../../components/atoms/jobs/CustomMultiSelect";
import { useLanguage } from "../../languageContext";
import ListCardToggle from "./ListCardToggle";

const JobsController = ({
  locations = [],
  departments = [],
  workTypes = [],
  onChangeWorkTypeHandler,
  onChangeLocationHandler,
  onChangeDepartmentHandler,
  searchHandler,
  toggle,
  toggleHandler,
}) => {
  const currentLanguage = useLanguage();
  return (
    <div className="jobs-control-components-wrapper ">
      <div className="jobs-control-components-container para-text">
        <div className="search-controller">
          <div className="search-input-container">
            <input
              onChange={searchHandler}
              className={`para-text border-${
                currentLanguage ? currentLanguage : "en"
              }`}
              type="text"
              placeholder="Search any Keyword such as Durability, BIW, Ohio, etc"
            />
            <div
              className="para-text"
              onClick={() => {
                toggleHandler(!toggle);
              }}
            ></div>
          </div>
          <div className="jobs-control-filter-container">
            {/* <select
              multiple={true}
              className={`border-${currentLanguage ? currentLanguage : "en"}`}
              name="location_search"
              id="location_search"
            >
              <option value={"PI"}>Select Location</option>
            </select> */}
            <MultiSelect
              onChangeHandler={onChangeLocationHandler}
              name="Location"
              data={locations}
            />
            <MultiSelect
              onChangeHandler={onChangeWorkTypeHandler}
              name="Work Type"
              data={workTypes}
            />
            <MultiSelect
              onChangeHandler={onChangeDepartmentHandler}
              name="Departments"
              data={departments}
            />
            {/* <select
              multiple={true}
              className={`border-${currentLanguage ? currentLanguage : "en"}`}
              name="work_type_search"
              id="work_type_search"
            >
              <option>Select Work Type</option>
            </select>
            <select
              multiple={true}
              className={`border-${currentLanguage ? currentLanguage : "en"}`}
              name="department_search"
              id="department_search"
            > */}
            {/* <option>Select Department</option>
            </select> */}
          </div>
        </div>
      </div>
      <div className="card-list-toggle">
        <ListCardToggle
          listView={toggle}
          cardView={!toggle}
          onClickHandler={toggleHandler}
        />
      </div>
    </div>
  );
};

export default JobsController;
