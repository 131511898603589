import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import JobList from "../molecules/Jobs/JobList";

import headerImage1 from "../images/jobs/image1.png";
import headerImage2 from "../images/jobs/image2.png";
import headerImage3 from "../images/jobs/image3.png";
import JobsHeader from "../molecules/Jobs/JobsHeader";
import JobsController from "../molecules/Jobs/JobsController";
import LoadMore from "../components/atoms/jobs/LoadMore";
import Loader from "../components/atoms/jobs/Loader";

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [locations, setLocations] = useState([]);
  const [workType, setWorkType] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listCardToggle, setListCardToggle] = useState(true);
  const [filterString, setFilterString] = useState({
    location: "",
    commitment: "",
    team: "",
  });
  // const [searchLoader, setSearchLoader] = useState(false);

  const JOBS_URL = "https://jobs.lever.co/v0/postings/gokenamerica1";

  function containsObject(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i]["value"] === obj.value) {
        return true;
      }
    }

    return false;
  }
  const deBounceSearch = (searchFn, delay) => {
    let timer;
    return function (...args) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        searchFn(...args);
      }, delay);
    };
  };
  const processData = (data, filter) => {
    let locations_arr = [];
    let workType_arr = [];
    let departments_arr = [];

    data.forEach((element) => {
      if (element.categories) {
        ifNotExistsAddToArray(locations_arr, element.categories.location);
        ifNotExistsAddToArray(workType_arr, element.categories.commitment);
        ifNotExistsAddToArray(departments_arr, element.categories.team);
      }
    });
    console.log("Processsing completed data is ", filter);
    if (filter === "") {
      setWorkType(workType_arr);
      setDepartments(departments_arr);
      setLocations(locations_arr);
    } else {
      if (filter["location"] === "") {
        console.log("not setting location");
        setLocations(locations_arr);
      }
      if (filter["commitment"] === "") {
        console.log("not setting commitment");
        setWorkType(workType_arr);
      }
      if (filter["team"] === "") {
        console.log("not setting team");
        setDepartments(departments_arr);
      }
    }
    function ifNotExistsAddToArray(arr = [], element = "") {
      let pushObject = {};
      pushObject["label"] = element;
      pushObject["value"] = element;

      if (arr !== undefined || element !== undefined) {
        // console.log(containsObject(pushObject, arr));
        if (!containsObject(pushObject, arr)) {
          arr.push(pushObject);
        }
      }
    }
  };
  const getAllJobs = async (filter = "", reset = false) => {
    try {
      setLoading(true);
      if (reset) {
        setJobs([]);
      }
      const requestUrl = `${JOBS_URL}?${filter["location"]}${filter["commitment"]}${filter["team"]}`;

      console.log("Post request on uri", requestUrl);
      const jobs1 = await axios.get(requestUrl);

      processData(jobs1.data, filter);

      setJobs(jobs1.data, filter);
      setAllJobs(jobs1.data);
      console.log(jobs1);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const onChangeLocationHandler = (selected) => {
    let locationString = "";
    selected.forEach((element) => {
      locationString += "location=" + element.value + "&";
    });
    setFilterString((prev) => {
      return { ...prev, location: locationString };
    });

    getAllJobs({ ...filterString, location: locationString }, true);

    // console.log("Location String", locationString);
    // const encodedString = encodeURI(locationString);
    // if (filterString !== "" && encodedString !== "") {
    //   setFilterString((prev) => `${prev}${encodedString}`);
    // } else {
    //   setFilterString(encodedString);
    // }
    // console.log(filterString);
    // getAllJobs(`${filterString}${encodedString}`, true);
  };
  const onChangeWorkTypeHandler = (selected) => {
    let commitmentString = "";
    selected.forEach((element) => {
      commitmentString += "commitment=" + element.value + "&";
    });
    setFilterString((prev) => {
      return { ...prev, commitment: commitmentString };
    });
    getAllJobs({ ...filterString, commitment: commitmentString }, true);
  };
  const onChangeDepartmentHandler = (selected) => {
    let teamString = "";
    selected.forEach((element) => {
      teamString += "team=" + element.value + "&";
    });
    setFilterString((prev) => {
      return { ...prev, team: teamString };
    });
    getAllJobs({ ...filterString, team: teamString }, true);
  };

  useEffect(() => {
    try {
      getAllJobs();
    } catch (err) {
      console.error(err);
    }
  }, []);
  function searchInKeys(job, keys, searchedText) {
    try {
      let i;
      // console.log("Keys lenght", keys.length);
      for (i = 0; i < keys.length; i++) {
        let result = false;

        let key = keys[i];
        // console.log("key ", key, "type of key", typeof key);

        if (key instanceof Object) {
          console.log("Object found", key);
          let keysObject = key;
          let firstKey = Object.keys(keysObject)[0];
          if (keysObject[firstKey] instanceof Array) {
            keysObject[firstKey].forEach((key) => {
              if (job[firstKey][key])
                if (
                  job[firstKey][key]
                    .toLowerCase()
                    .includes(searchedText.toLowerCase())
                ) {
                  console.log("Search Found", job);
                  result = true;
                  return result;
                }
            });
          }
        } else {
          console.log("Else ", key, typeof key);
          if (job[key])
            if (job[key].toLowerCase().includes(searchedText.toLowerCase()))
              result = true;
        }
        if (result) {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async function filterSearchedJobs(e) {
    try {
      // setLoading(true);
      let searchedText = e.target.value;
      if (searchedText === "") {
        setJobs(allJobs);
        setLoading(false);
        return;
      }

      console.log("called filter Search jobs");
      let searchedResults = allJobs.filter((job) => {
        // console.log(
        //   "Searching for matched ",
        //   job,
        //   searchInKeys(job, [
        //     "text",
        //     { categories: ["team", "commitment", "location"] },
        //   ]),
        //   searchedText
        // );
        if (
          searchInKeys(
            job,
            ["text", { categories: ["team", "commitment", "location"] }],
            searchedText
          )
        )
          return job;
      });
      console.log("Search Result is", searchedResults);
      setJobs(searchedResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setJobs(allJobs);
      console.error(error);
      throw error;
    }
  }
  const SearchJobs = deBounceSearch(filterSearchedJobs, 1500);
  const searchDebouncedJobs = (e) => {
    console.log("Turn on loader");
    setLoading(true);
    SearchJobs(e);
  };
  // const searchFunction =
  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/careers/"}
        description={
          "Find a job with Goken. Search Engineering, Design Engineering jobs at Goken at Goken America, Goken India."
        }
        title="JOBS: Find Engineering Job Opportunities with Goken"
      />

      <div className="jobs-page-wrapper">
        <JobsHeader
          paragraph={"Work With Us"}
          heading={"Find the right career fit for you!"}
          images={[headerImage1, headerImage2, headerImage3]}
        />
        <JobsController
          searchHandler={searchDebouncedJobs}
          onChangeDepartmentHandler={onChangeDepartmentHandler}
          onChangeLocationHandler={onChangeLocationHandler}
          onChangeWorkTypeHandler={onChangeWorkTypeHandler}
          locations={locations}
          workTypes={workType}
          departments={departments}
          toggle={listCardToggle}
          toggleHandler={setListCardToggle}
        />
        <Loader loading={loading} text={"Searching"} />

        <JobList jobs={jobs} toggle={listCardToggle} />
        {/* <Loader loading={loading} /> */}
        {/* <LoadMore
          buttonText={"Load More"}
          loading={loading}
          onClickHandler={addToJobs}
        /> */}
      </div>
    </Layout>
  );
};
export default Jobs;
