import React from "react";

const ImagesArray = ({ images = [] }) => {
  return (
    <div className="images-array-flex">
      {images.map((image) => {
        return (
          <div className="image-array-wrapper">
            <img src={image} alt="goken jobs" />
          </div>
        );
      })}
    </div>
  );
};

export default ImagesArray;
