import { Link } from "gatsby";
import React from "react";
import ArrowImage from "../../images/lifeatgoken/blackArrow.png";
const JobCard = ({ name, location, commitment, team, department, postId }) => {
  return (
    <div className="careers-job-wrapper">
      <Link
        to={`/careers/jobDescription/?jobId=${postId}/`}
        state={{ jobDeatils: postId }}
      >
        <div className="job-container">
          <div className="job-column-1">
            <p className="name semi-title bold">{name}</p>
            <p className="commitment para">{commitment}</p>
            {/* <p className="name">{team}</p>
            <p className="name">{department}</p> */}
          </div>
          <div className="job-column-2">
            <p className="location semi-para">{location}</p>
            <img src={ArrowImage} alt={name} />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default JobCard;
