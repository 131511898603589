import React from "react";
import ImagesArray from "./ImagesArray";

const JobsHeader = ({ paragraph, heading, images }) => {
  return (
    <div className="jobs-page-header-wrapper text-center">
      <h1 className="para-text no-margin">{paragraph}</h1>
      <h2 className="heading no-margin">{heading}</h2>
      <ImagesArray images={images} />
    </div>
  );
};

export default JobsHeader;
