import { Link } from "gatsby";
import React, { useEffect } from "react";
import { useState } from "react";
import ArrowImage from "../../images/lifeatgoken/blackArrow.png";

const JobCardView = ({
  name,
  location,
  commitment,
  description,
  postId,
  lists = [],
}) => {
  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  useEffect(() => {
    if (lists.length > 0) {
      let descriptionText1 = lists[0];
      setjobDescription(removeTags(descriptionText1.content));
      console.log("Description", descriptionText1);
    }
    return () => {};
  }, [jobDescription]);

  const [jobDescription, setjobDescription] = useState("");
  return (
    <div className="careers-job-card-wrapper">
      <Link to={`/careers/jobDescription/?jobId=${postId}/`}>
        <div className="job-card-container">
          <div className="job-card-row-1">
            <p className="semi-para">{commitment}</p>
          </div>
          <div className="job-card-row-2">
            <h3 className="no-margin title bold">{name}</h3>
          </div>
          <div className="job-card-row-3">
            <p className="para job-card-description-para">{jobDescription}</p>
          </div>
          <div className="job-card-row-4">
            <div className="location-wrapper">
              <p className="para">{location}</p>
            </div>
            <div className="apply-img-wrapper">
              <img
                src={ArrowImage}
                alt="Arrow Pointing to Explore more job details"
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default JobCardView;
